
import { defineComponent } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import AddUnitDrawer from '@/layout/header/partials/course/AddUnitDrawer.vue';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { object } from 'yup/lib/locale';
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  name: 'unit-list',
  components: {
    Datatable,
    AddUnitDrawer,
  },
  data() {
    return {
      load: false,
      componentKey: 0,
      tableHeader: [
      {
          name: 'unit',
          key: 'unit',
          width: '100px',
          // sortable: true,
        },
        {
          name: 'Unit Type',
          key: 'unit_type',
          // sortable: true,
        },
        {
          name: 'Symbol',
          key: 'symbol',
          // sortable: true,
        },
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
        },
      ],
      lists: [],
      search: '',
      tableData: [],
      data: {},
    };
  },
  async created() {
    await this.getUnitData();
    if (VueCookieNext.getCookie('finman_token') != null) {
        this.emitter.on('unit-added', async () => {
      });
    } else {
      this.$router.push('/404');
    }
  },
  methods: {
    setupunit(unit_data)
    {
      this.emitter.emit('unit_data',unit_data);
    },
    async getUnitData() {
      await ApiService.get('finmanInvoice/unit/list')
        .then((response) => {
          this.tableData = response.data.data;
          this.componentKey += 1;
          this.load = true;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    editUnit(data) {
      this.emitter.emit('unit-edit-type', data);
    },

    DeleteUnit(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('finmanInvoice/unit/delete/' + `${id}`)
            .then((response) => {
              this.emitter.emit('unit-added', true);
              Swal.fire('Deleted!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
});
